import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required Field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .required("Required Field"),
});

export const EditProductSchema = Yup.object().shape({
  material_code: Yup.string()
    .matches(
      /^[A-Za-z0-9/-\s][^*'`~!@#$%^&*()_={}:"<>?,.';]*$/,
      "Material Code must be valid"
    )
    .min(2, "Material Code shoulde be at least 2 characters")
    .max(64, "Material Code should not be more than 64 characters")
    .required("Required Field"),
  serial_number: Yup.string()
    .matches(
      /^[A-Za-z0-9/-][^*'`~!@#$%^&*()_={}:"<>?,';\s]*$/,
      "Serial Number must be valid"
    )
    .min(2, "Serial Number shoulde be at least 2 characters")
    .max(64, "Serial Number should not be more than 64 characters")
    .required("Required Field"),
  is_validate: Yup.string().required("Required Field"),
  dealer_code: Yup.string()
  // .matches(
  //   /^[A-Za-z0-9/-][^*'`~!@#$%^&*()_={}:"<>?,.';\s]*$/,
  //   "Serial Number must be valid"
  // )
  // .min(2, "Serial Number shoulde be at least 2 characters")
  // .max(64, "Serial Number should not be more than 64 characters")
  // .required("Required Field"),
});

export const ImportProductSchema = Yup.object().shape({
  product: Yup.string().required("Required Field"),
});

//change validation pattern for serial number.
export const AddProductSchema = Yup.object().shape({
  material_code: Yup.string()
    .matches(
      /^[ A-Za-z0-9/-\s][^*'`~!@#$%^&*()_={}:"<>?,.';]*$/,
      "Material Code must be valid"
    )
    .min(2, "Material Code shoulde be at least 2 characters")
    .max(64, "Material Code should not be more than 64 characters")
    .required("Required Field"),
  serial_number: Yup.string()
    .matches(
      /^[A-Za-z0-9/-][^*'`~!@#$%^&*()_={}:"<>?,';\s]*$/,
      "Serial Number must be valid"
    )
    .min(2, "Serial Number shoulde be at least 2 characters")
    .max(64, "Serial Number should not be more than 64 characters")
    .required("Required Field"),
  dealer_code:Yup.string()
  // .matches(
  //   /^[A-Za-z0-9/-][^*'`~!@#$%^&*()_={}:"<>?,';\s]*$/,
  //   "Serial Number must be valid"
  // )
  // .min(2, "Serial Number shoulde be at least 2 characters")
  // .max(64, "Serial Number should not be more than 64 characters")
  // .required("Required Field"),
});
