// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../AccessLogUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useAccessLogUIContext } from "../AccessLogUIContext";

export function AccessLogTable({
  isLoading,
  accessLogList,
  accessLogListTotalCount,
}) {
  const accessLogUIContext = useAccessLogUIContext();
  const accessLogUIProps = useMemo(() => {
    return {
      ids: accessLogUIContext.ids,
      setIds: accessLogUIContext.setIds,
      queryParams: accessLogUIContext.queryParams,
      setQueryParams: accessLogUIContext.setQueryParams,
      openViewDialog: accessLogUIContext.openViewDialog,
      openEditDialog: accessLogUIContext.openEditDialog,
      openDeleteDialog: accessLogUIContext.openDeleteDialog,
    };
  }, [accessLogUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "material_code",
      text: "MATERIAL CODE",
      sort: true,
      sortCaret: sortCaret,
      attrs: {
        "data-label": "MATERIAL CODE",
      },
    },
    {
      dataField: "serial_number",
      text: "SERIAL NUMBER",
      sort: true,
      sortCaret: sortCaret,

      attrs: {
        "data-label": "SERIAL NUMBER",
      },
    },
    {
      dataField: "dealer_code",
      text: "Dealer Code",
      formatter: (cell, row) => (row.dealer_code ? row.dealer_code : "-"),
      sort: true,
      sortCaret: sortCaret,
      attrs: {
        "data-label": "Dealer Code",
      },
    },
    {
      dataField: "response",
      text: "RESPONSE CODE",
      formatter: columnFormatters.ResponseColumnFormatter,
      attrs: {
        "data-label": "RESPONSE CODE",
      },
    },
    // {
    //   dataField: "response",
    //   text: "RESPONSE",
    //   attrs: {
    //     "data-label": "RESPONSE",
    //   },
    // },

    {
      dataField: "createdAt",
      text: "VALIDATE DATE",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateTimeColumnFormatter,
      style: {
        minWidth: "170px",
      },
      attrs: {
        "data-label": "DATE",
      },
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: accessLogUIProps.openViewDialog,
        openEditDialog: accessLogUIProps.openEditDialog,
        openDeleteDialog: accessLogUIProps.openDeleteDialog,
      },
      classes: "pr-md-0 text-right notresponsive",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: accessLogListTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: accessLogUIProps.queryParams.pageSize,
    page: accessLogUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={accessLogList ? accessLogList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  accessLogUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              {/* <PleaseWaitMessage entities={accessLogList} /> */}
              <NoRecordsFoundMessage entities={accessLogList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
