import React, { useEffect, useState } from "react";
import { ButtonToolbar } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import { useLocalStorage } from "../../utils/useLocalStorage";
import ErrorModal from "./ErrorModal";
import UpdatedSuccessfulModal from "./UpdatedSuccessfulModal";
import { EditProductSchema } from "../../utils/FormikSchema";
import { editProductApi } from "../../../axios/services/services";

const initialValues = {
  material_code: "",
  serial_number: "",
  is_validate: "0",
  dealer_code:""
};

function EditProductModal({
  isEditProductModalShow,
  data,
  hideEditProductModal,
  handleSuccessOfEditProduct,
}) {
  const [loading, setLoading] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useEffect(() => {
    const { id, material_code, serial_number, is_validate,dealer_code } = data || {};
    formik.setFieldValue("material_code", material_code);
    formik.setFieldValue("serial_number", serial_number);
    formik.setFieldValue("is_validate", is_validate ? "1" : "0");
    formik.setFieldValue("dealer_code", dealer_code);
  }, [data]);

  const formik = useFormik({
    initialValues,
    validationSchema: EditProductSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      const { id } = data || {};
      const { material_code, serial_number, is_validate ,dealer_code} = values;
      enableLoading();
      let editProductObj = {
        id,
        materialCode: material_code,
        serialNumber: serial_number,
        dealer_code :dealer_code,
        is_validate: is_validate === "1" ? true : false,
      };
      const editProductResponse = await editProductApi(editProductObj);
      disableLoading();
      const { Error } = editProductResponse || {};
      if (Error) {
        const { message } = Error || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      resetForm();
      handleSuccessOfEditProduct(Math.random());
      setSuccessMessage("Product Edited Successfully.");
      handleSuccessModalShow();
      setTimeout(() => {
        hideEditProductModal();
      }, 2500);
    },
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };
  return (
    <>
      <Modal
        show={isEditProductModalShow}
        onHide={() => {
          formik.resetForm();
          hideEditProductModal();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            Edit Product Details
            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={hideEditProductModal}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group mt-2">
              <input
                placeholder="Material Code*"
                autoComplete="off"
                type="text"
                className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
                name="material_code"
                {...formik.getFieldProps("material_code")}
              />
              {formik.touched.material_code && formik.errors.material_code ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.material_code}
                  </div>
                </div>
              ) : null}

              <input
                placeholder="Serial Number*"
                autoComplete="off"
                type="text"
                className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
                name="serial_number"
                {...formik.getFieldProps("serial_number")}
              />
              {formik.touched.serial_number && formik.errors.serial_number ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.serial_number}
                  </div>
                </div>
              ) : null}
              <input
                placeholder="Dealer Code"
                autoComplete="off"
                type="text"
                className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
                name="dealer_code"
                {...formik.getFieldProps("dealer_code")}
              />
              {formik.touched.dealer_code && formik.errors.dealer_code ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.dealer_code}
                  </div>
                </div>
              ) : null}
              <select
                className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
                name="is_validate"
                placeholder="Filter by Validate"
                {...formik.getFieldProps("is_validate")}
              >
                <option value="1">Validated</option>
                <option value="0">Not Validated</option>
              </select>
            </div>

            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                type="submit"
                variant="btn btn-success mr-3"
                disabled={loading}
              >
                Edit Product
                {loading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={() => {
                  formik.resetForm();
                  hideEditProductModal();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
export default EditProductModal;
